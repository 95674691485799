import $ from "jquery";
import { getCSRFTokenValue } from "../CsrfToken";

const SelectElement = (selectId, valueToSelect) => {
  $(selectId).val(valueToSelect).trigger("change");
};

const setFields = (e) => {
  const el = e.target;
  const { id } = el;
  const baseId = id.substring(0, id.length - 4); // Remove 'info'
  const inputFields = [
    "address",
    "city",
    "company",
    "email",
    "phone",
    "first_name",
    "last_name",
    "title",
  ];
  const selectFields = ["country", "state"];

  $.ajax({
    url: el.value,
    dataType: "json",
    headers: {
      "X-CSRF-Token": getCSRFTokenValue(),
    },
    success(data) {
      $.each(inputFields, (index, v) => {
        if (data[v]) {
          $(`#${baseId}${v}`).val(data[v]);
        }
      });
      $.each(selectFields, (index, v) => {
        if (data[v]) {
          SelectElement(`#${baseId}${v}`, data[v]);
        }
      });
    },
    error(response) {
      console.log(response);
    },
  });
};

const init = () => {
  const occupantSelects = document.querySelectorAll(
    "[data-occupants-fill-out]",
  );

  if (occupantSelects.length > 0) {
    occupantSelects.forEach((el) => {
      el.addEventListener("change", setFields);
    });
  }
};

document.addEventListener("DOMContentLoaded", init);
document.addEventListener("occupantsfield:init", init);
